.custom-input-group {
  border-radius: 10px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  &-text {
    border-radius: 10px 0 0 10px !important;
    border: none !important;
    background: transparent !important;
    // background: rgba(255, 255, 255, 0.1) !important;
  }
}

.input {
  // background: rgba(255, 255, 255, 0.1) !important;
  background: transparent !important;
  border: none !important;
  color: #FFFFFF !important;
  margin-left: 0px !important;
  border-radius: 0 10px 10px 0 !important;
  &:focus {
    border: none !important;
  }
}