.auth {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e7e8ea;

  &-form {
    width: 60%;
    height: 100vh;
    background-color: #1d1b1b;
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #b7b9c3;
      width: 363px;
      margin-left: 20%;

      img {
        width: 66px;
        height: 66px;
        margin-bottom: 18px;
      }

      h2 {
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: #b7b9c3;
        margin-bottom: 20px;
      }
      fieldset {
        width: 100%;
        margin-top: 35px;
        p {
          font-size: 13px;
          line-height: 16px;
          color: #f881ba;
        }

        label {
          display: flex;
          flex-direction: column;
          font-size: 15px;
          line-height: 20px;
        }

        input {
          margin-top: 5px;
        }

        .password {
          margin-top: 13px;
        }

        button {
          border-radius: 10px;
          background: #88bc87;
          border: none;
          color: #f5f8fe;
          padding: 14px;
          margin: 20px 0;
        }
      }
    }
  }
  &-img {
    width: 40%;
    height: 100vh;
    background-image: url('../../../assets/images/auth_background.jpg');
    background-size: cover;
    background-position: center;
    & img {
      display: none;
      height: auto;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 1750px) {
  .auth {
    &-form {
      width: 55%;
      & form {
        margin-left: 0;
      }
    }
    &-img {
      width: 45%;
    }
  }
}

@media (max-width: 1080px) {
  .auth {
    &-form {
      width: 100%;
      padding: 0 20px;
    }
    &-img {
      display: none;
    }
  }
}
