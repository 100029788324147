@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
    url('../../assets/fonts/SFProDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Thin Italic'), local('SFProDisplay-ThinItalic'),
    url('../../assets/fonts/SFProDisplay-ThinItalic.woff')
      format('woff');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
    url('../../assets/fonts/SFProDisplay-Ultralight.woff')
      format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Ultralight Italic'),
    local('SFProDisplay-UltralightItalic'),
    url('../../assets/fonts/SFProDisplay-UltralightItalic.woff')
      format('woff');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
    url('../../assets/fonts/SFProDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
    url('../../assets/fonts/SFProDisplay-LightItalic.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url('../../assets/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display RegularItalic'),
    local('SFProDisplay-RegularItalic'),
    url('../../assets/fonts/SFProDisplay-RegularItalic.woff')
      format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('../../assets/fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
    url('../../assets/fonts/SFProDisplay-MediumItalic.woff')
      format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display SemiBold'), local('SFProDisplay-SemiBold'),
    url('../../assets/fonts/SFProDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display SemiBoldItalic'),
    local('SFProDisplay-SemiBoldItalic'),
    url('../../assets/fonts/SFProDisplay-SemiboldItalic.woff')
      format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('../../assets/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
    url('../../assets/fonts/SFProDisplay-BoldItalic.woff')
      format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
    url('../../assets/fonts/SFProDisplay-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display BlackItalic'), local('SFProDisplay-BlackItalic'),
    url('../../assets/fonts/SFProDisplay-BlackItalic.woff')
      format('woff');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
    url('../../assets/fonts/SFProDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
    url('../../assets/fonts/SFProDisplay-HeavyItalic.woff')
      format('woff');
  font-weight: 900;
  font-style: italic;
}

body {
  background-color: #2c2f36 !important;
  background-image: url("../../assets/images/app_background.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  font-family: 'SF Pro Display', Arial, sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  .border-danger {
    border: 1px solid #F881BA !important;
  }

  .text-danger {
    color: #F881BA !important;
  }

  .btn-success {
    background-color: #88BC87 !important;
    border: 1px solid #88BC87 !important;
    // border: none !important;
  }

  .text-success {
    color: #88BC87 !important;
  }
  .bg-success {
    background-color: #555c55 !important;
  }

  .cancel {
    background-color: white;
    color: black;
    border: 1px solid white !important;
  }
}

.bg-custom-full-info {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none;
  color: white !important;
  &:hover {
    box-shadow: 0 8px 25px -8px #82868b;
  }
}

#root {
  .wrapper {
    max-width: 1360px;
    margin: 0 auto;
  }

  .card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
  }
}

.select__control {
  padding: 0.5rem;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid transparent !important;

  &:hover {
   //border-color:  !important;
   border: 1px solid #88BC87 !important;
  }
  & input {
    padding: 0 !important;
    opacity: 0 !important;
  } 
}

.select__control--is-focused {
  border-color: #88bc87 !important;
  border-radius: 10px !important;
}

.select__control--menu-is-open {
  border-radius: 10px 10px 0 0 !important;
  box-sizing: border-box;
}

.select__indicator-separator {
  display: none;
}

.select__single-value {
  color: #fff !important;
}

.select__multi-value__remove {
  border-radius: 50%;
  padding: 0px 4px;
}
.select__multi-value {
  padding: 5px 10px 5px 20px!important;
}

.basic-multi-select {
  border-radius: 10px;
  box-sizing: border-box;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;

  .select__placeholder {
    color: #fff;
    opacity: 0.8;
  }
}

.select__menu {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  border: 2px solid #88bc87;
  border-radius: 0 0 1rem 1rem;

  .select__menu-list {
    border-radius: 0 0 1rem 1rem;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(52, 52, 52, 0.78) !important;
      border-radius: 20px;
    }

    &::-webkit-scrollbar {
      background-color: #858585 !important;
      border-radius: 20px;
    }
  }
}

.select__input {
  color: #fff !important;
}

.select__option {
  padding: 0.5rem 1rem;
  color: #fff !important;
  overflow: hidden;
}

.select__value-container--is-multi {
  gap: 0.5rem;

  .select__multi-value {
    border-radius: 1.5rem;
    padding: 0 0.5rem;
    background-color: #fff;
    color: #88bc87 !important;
  }

  .select__multi-value__label {
    color: #88bc87 !important;
    margin-right: 0.5rem;
    font-size: 16px;
  }
}

.select__single-value,
.select__placeholder {
  padding-left: 0.5rem;
}

input,
textarea {
  background: #292a2c !important;
  border: 1px solid #7b7b7b !important;
  border-radius: 10px;
  padding: 14px 10px;
  outline: none;
  color: #B7B9C3;
  &:focus {
    border: 1px solid #88BC87 !important;
  }
}

//photo

form {
  .new-photo {
    background: #EEFCEE;
    box-shadow: 0 2px 4px rgba(90, 141, 238, 0.4);
    color: #66AE64;
    font-size: 16px;
    line-height: 19px;
    border: none !important;
    border-radius: 10px;
    padding: 10px 25px !important;
    font-weight: 400;
    &:hover {
      color: #4e994d;
    }
  }

  .delete-photo {
    background: #F881BA;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    border: none;
  }
}

.img .btn-outline-danger {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid #f80a7c !important;
  color: white;
}

.modal-content {
  h3 {
    font-family: 'Pacifico', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 107.5%;
    text-align: center;
    letter-spacing: 1px;
    color: #18191d;
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 112%;
    text-align: center;
    color: #18191d;

    span {
      color: #88bc87;
      font-weight: 600;
    }
  }

  .modal-footer {
    border-top: 2px solid rgba(24, 25, 29, 0.1);

    h2 {
      font-family: 'Poppins', sans-serif !important;
      font-style: normal !important;
      font-weight: 900;
      font-size: 20px;
      line-height: 112%;
      text-align: center;
      color: #18191d;
    }
  }
}

//меню поверх инпута с поиском
//.custom-static-for-menu {
//  .input-group > .form-control, .input-group > .form-select, .input-group {
//    position: static !important;
//  }
//}

.card {
  position: static !important;
  &-input {
    background: rgba(255, 255, 255, 0.1) !important;
    border-radius: 10px !important;
    color: #ffffff !important;
    border-color: transparent !important;
  }
  &-subtitle {
    font-weight: 400;
    color: #ffffff;
    font-size: 24px;
  }
}

.menu-language {
  button {
    border: none !important;
    background-color: inherit !important;
    width: max-content !important;
    border-radius: 0;
    padding: 5px;
  }

  .dropdown-menu {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
    width: max-content !important;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.06) !important;
    border-radius: 10px !important;
    z-index: 9999;

    span {
      width: 100%;
    }
  }
}

.delete-button {
  border-radius: 10px !important;
  color: white !important;
  height: fit-content;
}

.br-10 {
  border-radius: 10px !important;
}

textarea {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: #dae1e7;
    border-radius: 20px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 20px;
  }
}