.crop-preview {
  &-item {
    &_big {
      &.isRound {
        width: 100px !important;
        height: 100px !important;
        border-radius: 50%;
        & > .advanced-cropper-preview__content {
          width: 100px !important;
          height: 100px !important;
          border-radius: 50%;
        }
      }
    }

    &_small {
      width: 75px !important;
      height: 75px !important;
      border-radius: 50%;
      & > .advanced-cropper-preview__content {
        width: 75px !important;
        height: 75px !important;
        border-radius: 50%;
      }
    }
  }
}
