.table-responsive {
  border-radius: 0.5rem 0.5rem 0 0;
  & table {
    & > :not(caption) {
      th {
        padding: 16px !important; 
        background: rgba(255, 255, 255, 0.1) !important;
      }
      td {
        color: white;
        border-bottom: 0.2px solid #c7cfd6;
        padding: 16px !important;
        background: rgba(255, 255, 255, 0.1) !important;
      }
    }
  }
}

.table-div {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 0 0 0.5rem 0.5rem;
  height: 1rem;
  width: 100%;
}
