header {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  padding: 18px 0;
  margin-bottom: 20px;

  .wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  nav {
    a {
      margin-right: 50px;
      text-decoration: none;
      color: #88bc87;
      font-size: 16px;
      line-height: 22px;
      white-space: nowrap;
      &:hover {
        color: #4e994d;
      }
    }
  }
}
